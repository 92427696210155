/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Logo } from '../logo/logo';
import './footer.css';

export const Footer = () => {
  return <div className='footer'> 
    <Logo />
    <div style={{margin: '12px 0'}}>
      <div style={{textAlign: 'center', fontSize: 22}}>
      </div>
      {/* <div style={{textAlign: 'center', marginTop: 12}}>
        <a href={`https://tarch.co`} target='_blank'>https://tarch.co</a>
      </div> */}
    </div>

    <div style={{display: 'flex', flexDirection: 'column', margin: '16px 0'}}>
      <div style={{marginLeft: 8}}>
        <h4 style={{color: "#f7e1aa"}}>CONTACT:</h4>
        <div>
          <a href={`https://www.instagram.com/tarch.co/`} target='_blank'>
            <span style={{fontSize: 22, marginRight: 8 }}><i className='fa-brands fa-instagram' /></span>
            <span>Instagram</span>
          </a>
        </div>
        <div>
          <a href={`https://www.tiktok.com/@tarch.co`} target='_blank'>
            <span style={{fontSize: 22, marginRight: 8 }}><i className='fa-brands fa-tiktok' /></span>
            <span>Tiktok</span>
          </a>
        </div>
        <div>
          <a href={`https://wa.me/+573012329000?text=${encodeURIComponent("Hi, I'm interested in purchasing shoes via tarch.shop")}`} target='_blank'>
            <span style={{fontSize: 22, marginRight: 8}}><i className='fa-brands fa-whatsapp' /></span>
            <span>+57 3012329000</span>
          </a>
        </div>
        <div>
          <a href={`mailto:ventas@tarch.co`} target='_blank'>
            <span style={{fontSize: 22, marginRight: 8}}><i className='fa-regular fa-envelope-open' /></span>
            <span>ventas@tarch.co</span>
          </a>
        </div>
        
        <div style={{marginTop: 32}}>
          <h4 style={{color: "#f7e1aa"}}>SHOWROOM MEDELLIN:</h4>
          <div>Cra 25# 12sur - 59 of. 235 Complex de los Balsos</div>

          <h4 style={{color: "#f7e1aa", marginTop: 12}}>SHOWROOM BOGOTÁ:</h4>
          <div>Calle 79B # 7 - 38 Casa Santa Maria</div>
        </div>
      </div>
    </div>
    <div style={{display: 'flex', flexWrap: 'wrap', margin: '16px 0'}}>
      <div style={{marginLeft: 8}}>
        <a href={`${process.env.REACT_APP_CLIENT_URL}/#/terms-of-refund`} target='_blank'>Refund Policy</a>
      </div>
      <div style={{marginLeft: 8}}>
        <a href={`${process.env.REACT_APP_CLIENT_URL}/#/terms-of-service`} target='_blank'>Terms of service</a>
      </div>
      <div style={{marginLeft: 8}}>
        <a href={`${process.env.REACT_APP_CLIENT_URL}/#/privacy-policy`} target='_blank'>Privacy Policy</a>
      </div>
      <div style={{marginLeft: 8}}>
        <a href={`${process.env.REACT_APP_CLIENT_URL}/#/about-us`}>About us</a>
      </div>
    </div>

    <div style={{display: 'flex', alignItems: 'center'}}>
      <span>Payments Powered by: </span>
      <span style={{fontSize: 28, marginLeft: 8}}><i className='fa-brands fa-stripe' /></span>
    </div>

    <div style={{textAlign: 'center'}}>Copyright Tarch Inc, 2024</div>
  </div>
}