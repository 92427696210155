import { IImage } from "../components/gallery-01/gallery-01";
import * as bela from './bela';
import * as andros from './andros';
import * as emma from './emma';
import * as nolita from './nolita';
import * as nebraska from './nebraska';

export interface IClientProductPage {
  reference: string;
  clientFolder: string;
  name: string;
  header01: string;
  headerTitle: any;
  gallery01: IImage[];
  gallery02: IImage[];
  description: any;
}
export const get = (reference: string): IClientProductPage | undefined => {
  switch (reference) {
    case `bela-glossy-black-and-white`:
      return bela.glossyBlackAndWhite();

    case `andros-bold-black`:
      return andros.boldBlack();

    case `emma-black`:
      return emma.black();

    case `nolita-glitter-black`:
      return nolita.glitterBlack();
    case `nolita-serpentine-neon`:
      return nolita.serpentineNeon();

    case `nebraska-copper`:
      return nebraska.copper();
    case `nebraska-fire`:
      return nebraska.fire();

    default:
      return undefined;
  }
}
