import React from 'react';
import { IClientProductPage } from '../product-locations';


export const black = (): IClientProductPage => {
  const clientFolder = `images/emma-black`;
  return {
    reference: `emma-black`,
    clientFolder,
    name: 'Emma Black',
    header01: `${clientFolder}/header-01.jpg`,
    headerTitle: <>
      <h1 style={{textTransform: 'uppercase'}}>Emma</h1>
      <h3 style={{textTransform: 'uppercase', color: 'black'}}>Black</h3>
    </>, 
    gallery02: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
    ],
    gallery01: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
    ],
    description: <>
      <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
      <p>
        Our Emma ankle boots are classic boots from our brand, and we've brought them back in black with a rocker touch.
        <br />
        The metallic eyelets and cutouts give them a chic touch.
        <br />
        They are super versatile and especially super comfortable, making them a great purchase as we assure you that you will love them even more than we do.
        <br />
        Feel like a girl boss wearing our black EMMA.
      </p>
      <div>
        <h4 style={{margin: '8px 0'}}>MATERIALS</h4>
        <ul>
          <li>Boot in black cow leather made in Colombia with metallic eyelets.</li>
          <li>Lining in sheepskin leather.</li>
          <li>Luxury insole in sheepskin leather with a print.</li>
          <li>Neolite sole</li>
        </ul>

        <h4 style={{margin: '8px 0'}}>SIZE</h4>
        <p>Our sizing is standard; if you are between sizes, we recommend choosing the larger size
            (e.g., if you are size 37.5, we recommend buying size 38).</p>

        <p>5cm heel that is super comfortable."</p>
        
        <p>Made by hand, with love, by our artisans in Colombia</p>
      </div>
    </>,
  }
}