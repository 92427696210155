import React from 'react';
import {AddressElement} from '@stripe/react-stripe-js';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { api } from '../../helpers';
import { IAddress } from '../../interfaces';

export const AddressForm = (props: { onComplete: () => unknown}) => {
  // const [canSubmit, setCanSubmit] = React.useState(false);

  let appCtx = useApp();

  const updateStripe = (name: string, address: IAddress) => {
    try {
      const id = appCtx.stripePaymentId;
      const email = appCtx.email;
      const cart = appCtx.cart;
      const r = api.call(`${process.env.REACT_APP_API_URL}/stripe-intent-update`, { id, name, address, email, cart });
      r.request.then((req: any) => {
        appCtx.changeTotalPrice(req.taxCalculation.amount_total);
        appCtx.changeTax(req.taxCalculation.tax_amount_exclusive);
      });
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <form>
      <h3 style={{margin: '12px 0'}}>Shipping</h3> 
      <AddressElement options={{mode: 'shipping', allowedCountries: ['US']}} 
        onChange={(event) => {
          if (event.complete) { 
            appCtx.changeAddressName(event.value.name);
            appCtx.changeAddress(event.value.address);
            updateStripe(event.value.name, event.value.address);
          }
        }} 
      />
      {/* <div style={{margin: '12px 0 0 0'}}>
        <input type={'checkbox'} id={`id-same-as-shipping`} checked={sameAsShipping} onChange={(e) => setSameAsShipping(!sameAsShipping)} />
        <label htmlFor='id-same-as-shipping'>Billing address is same address as shipping</label>
      </div> */}
      {/* <Button 
        disabled={!appCtx.address} 
        onClick={() => props.onComplete() }
        style={{width: '100%'}}
        >Continue</But  szston> */}
    </form>
  );
};
