import React from 'react';
import { IClientProductPage } from '../product-locations';


export const glossyBlackAndWhite = (): IClientProductPage => {
    const clientFolder = `images/bela-glossy-black-and-white`;
    return {
      reference: `bela-glossy-black-and-white`,
      clientFolder,
      name: 'Bela Glossy Black and White',
      header01: `${clientFolder}/header-01.jpg`,
      headerTitle: <>
        <h1 style={{textTransform: 'uppercase'}}>Bela Glossy</h1>
        <h3 style={{textTransform: 'uppercase', color: 'black'}}>Black and White</h3>
      </>, 
      gallery02: [
        {src: `${clientFolder}/img-0.jpg`, type: 'image'},
        {src: `${clientFolder}/img-1.jpg`, type: 'image'},
        {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      ],
      gallery01: [
        {src: `${clientFolder}/img-0.jpg`, type: 'image'},
        {src: `${clientFolder}/img-1.jpg`, type: 'image'},
        {src: `${clientFolder}/img-2.jpg`, type: 'image'},
        {src: `${clientFolder}/vertical-001.mp4`, type: 'video'},
      ],
      description: <>
        <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
        <p>These bi-color are a classic we could not wait to have in TARCH,
        our new design is here to stay, we hope you love them as much as we do. &nbsp;
        </p>
        <div>
          <h4 style={{margin: '8px 0'}}>MATERIALS</h4>
          <ul>
            <li>ITALIAN charol leather in two colors.</li>
            <li>Inside in badana leader</li>
            <li>Luxury badana leather</li>
            <li>Neolite sole</li>
            <li>Wooden heel, wrapped in a 2.5cm leather.</li>
          </ul>
  
          <h4 style={{margin: '8px 0'}}>SIZE</h4>
          <p>Our fit tends towards the smaller side, if you are between 2 sizes, we recommend the upper size. 
          (e.g. if you are 5.5, we recommend 6)</p>
          
          <p>Made by hand, with love, by our artisans in Colombia</p>
        </div>
      </>,
    }
  }