/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import { createHashRouter, RouterProvider } from "react-router-dom";
import { PurchaseCompleted } from './routes/purchase-comlpeted/purchase-completed';
import { Home } from './routes/home/home';
import * as helpers from './helpers';
import './app.css';
import { TOSRefund } from './components/tos/refund/refund-policy';
import { TOSPrivacyPolicy } from './components/tos/privacy-policy/privacy-policy';
import { TOSTermsAndConditions } from './components/tos/terms-and-conditions/terms-and-conditions';
import { TableSizes } from './components/table-sizes/table-sizes';
import { Footer } from './components/footer/footer';
import { Checkout } from './routes/checkout/checkout';
import { AboutUs } from './routes/about-us/about-us';
import { Product } from './routes/product/product';
import { Toast } from './components/toast/toast';
import { useApp } from './context-providers/app-provider/app-provider';
import { ICart, IProduct } from './interfaces';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_CLIENT_SECRET}`);

export const getStripePromise = () => stripePromise;

export default function App() {
  const [render, setRender] = React.useState(false);
  let appCtx = useApp();

  const [router] = React.useState(createHashRouter([
    { path: '/about-us', element: <AboutUs /> },
    { path: '/terms-of-refund', element: <TOSRefund /> },
    { path: '/privacy-policy', element: <TOSPrivacyPolicy /> },
    { path: '/terms-of-service', element: <TOSTermsAndConditions /> },
    { path: '/purchase-completed', element: <PurchaseCompleted /> },
    { path: '/checkout', element: <Checkout /> },
    { path: "/p/:reference", element: <Product /> },
    { path: "", element: <Home /> },
  ]));

  React.useEffect(() => {
    let ignore = false;
    
    const resize = (e?: Event) => {
      if (ignore) { return }
      helpers.bus.emit('RESIZE', { isPortrait: window.innerWidth < window.innerHeight, width:  window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("orientationchange", resize); 
    // window.screen.orientation.addEventListener('change', resize);
    window.addEventListener("resize", resize);
    const tm = setTimeout(() => resize(), 32);

    const init = async () => {
      const rCat = await (helpers.api.call(`${process.env.REACT_APP_API_URL}/get-categories`)).request;
      if (ignore) { return; }
      appCtx.changeCategory('All');
      appCtx.changeCategories([{ name: 'All' }, ...rCat.categories]);

      const rProd = await (helpers.api.call(`${process.env.REACT_APP_API_URL}/get-products`)).request;
      if (ignore) { return; }
      const products = rProd.products as IProduct[];
      appCtx.changeProducts(products);
      const newCart = filterNewCart([...appCtx.cart], products);
      appCtx.changeCart(newCart);
      appCtx.changeTotalPrice(newCart.reduce((a, b) => a + b.priceInCents, 0));

      setRender(true);
    }

    try {
      init();
    } catch (e) {
      console.error(e);
    }
    return () => {
      ignore = true;
      clearTimeout(tm);
      window.removeEventListener("orientationchange", resize);
      window.removeEventListener("resize", resize);
    }
  }, []);
  return !render ? <></> : <>
    <Toast />
    <TableSizes />
    <RouterProvider router={router} />

    <Footer />
  </>
}

export const filterNewCart = (previousCart: ICart[], products: IProduct[]) => {  
  // update cart prices to server prices
  const newCart: ICart[] = [];
  previousCart.forEach(it => {
    const p = products.find(k => k.reference === it.reference);
    const q = it.quantity;
    if (!p // no product
      || !helpers.isNumeric(q)
      || (p.sizes[it.size] ?? 0) - q < 0) {  // size no longer available
        return undefined; 
    }
    newCart.push({...it, priceInCents: p.priceUsd * 100});
  });
  return newCart;
}
