import React from 'react';
import { IClientProductPage } from '../product-locations';


export const glitterBlack = (): IClientProductPage => {
  const clientFolder = `images/nolita-glitter-black`;
  return {
    reference: `nolita-glitter-black`,
    clientFolder,
    name: 'Nolita Glitter Black',
    header01: `${clientFolder}/header-01.jpg`,
    headerTitle: <>
      <h1 style={{textTransform: 'uppercase'}}>Nolita Glitter</h1>
      <h3 style={{textTransform: 'uppercase', color: 'black'}}>Black</h3>
    </>, 
    gallery02: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
      {src: `${clientFolder}/img-5.jpg`, type: 'image'},
    ],
    gallery01: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
      {src: `${clientFolder}/img-5.jpg`, type: 'image'},
    ],
    description: <>
      <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
      <p>Our Nolita GLITTER are in one of our favorite textures, trendy and at the same time a timeless piece being a classic silhouette and in metallics that always look good.</p>
      <p>They are easy to use and match, and we hope you like them as much as we do.</p>

      <p>(In the photos, you can see the reference with other laces)</p>
      <div>
        <h4 style={{margin: '8px 0'}}>LACES</h4>
        <p>The black Nolita GLITTER come with medium gray laces with lurex.</p>
      </div>
      <div>
        <h4 style={{margin: '8px 0'}}>MATERIALS</h4>
        <ul>
          <li>Sides and toe cap in textile fabric covered in glitter</li>
          <li>Eyestays and tongue in leather</li>
          <li>Backs in embroidered metallic purple leather</li>
          <li>Lining in sheepskin leather</li>
          <li>Luxury insole in sheepskin leather with print</li>
          <li>Beige natural rubber sole</li>
        </ul>

        <h4 style={{margin: '8px 0'}}>SIZE</h4>
        <p>Our fit is normal to large; if you are between sizes, we recommend the smaller size
            (e.g., if you are size 37.5, we recommend buying size 37).</p>
        
        <p>Made by hand, with love, by our artisans in Colombia</p>
      </div>
    </>,
  }
}

export const serpentineNeon = (): IClientProductPage => {
  const clientFolder = `images/nolita-serpentine-neon`;
  return {
    reference: `nolita-serpentine-neon`,
    clientFolder,
    name: 'Nolita Serpentine Neon',
    header01: `${clientFolder}/header-01.jpg`,
    headerTitle: <>
      <h1 style={{textTransform: 'uppercase'}}>Nolita Serpentine</h1>
      <h3 style={{textTransform: 'uppercase', color: 'black'}}>Neon</h3>
    </>, 
    gallery02: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
    ],
    gallery01: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
    ],
    description: <>
      <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
      <p>
        Our Nolita SERPENTINE are those different and cool sneakers that will add a POP of color to your outfit, 
        they are very easy to combine and best of all, timeless. We hope you love them just as we do.
        With gray or silver laces, they also look super cute and will give an unexpected touch.
      </p>
      <div>
        <h4 style={{margin: '8px 0'}}>LACES</h4>
        <p>The Nolita SERPENTINE come with black laces.</p>
      </div>
      <div>
        <h4 style={{margin: '8px 0'}}>MATERIALS</h4>
        <ul>
          <li>Sides in neon yellow Italian snake leather</li>
          <li>Toe and heel in bovine leather</li>
          <li>Tongue in suede leather</li>
          <li>Lining in sheepskin leather</li>
          <li>Luxury insole in sheepskin leather with print</li>
          <li>Beige natural rubber sole</li>
        </ul>

        <h4 style={{margin: '8px 0'}}>SIZE</h4>
        <p>Our fit is normal with a tendency to be large, if you are between sizes, we recommend the smaller size
            (e.g., if you are size 37.5, we recommend buying size 37).</p>
        
        <p>Made by hand, with love, by our artisans in Colombia</p>
      </div>
    </>,
  }
}