import React from 'react';
import { api } from '../../helpers';
import { IAddress } from '../../interfaces';
import moment from 'moment';
import numeral from 'numeral';
import { Button } from '../../components/button/button';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/logo/logo';
import { useApp } from '../../context-providers/app-provider/app-provider';

interface IPurchaseCompleted {
  id: string,
  products: { reference: string, size: string, quantity: string, priceInCents: number }[],
  name: string,
  totals: {
    tax: number,
    total: number,
  },
  created: number,
  currency: string,
  email: string,
  shipping: IAddress,
}
export const PurchaseCompleted = () => {
  const [info, setInfo] = React.useState<IPurchaseCompleted>();
  let navigate = useNavigate();
  let appCtx = useApp();
  React.useEffect(() => {
    if (!info) { return; }
  },[info]);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get('payment_intent');
    const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');
    let ignore = false;
    try {
      appCtx.changeCart([]);
      
      const r = api.call(`${process.env.REACT_APP_API_URL}/stripe-intent-retrieve`, { paymentIntentId, paymentIntentClientSecret });
      r?.request.then((req: any) => {
        if (ignore) { return; }
        setInfo(req);
        console.log(req);

        gtag("event", "page_view", {
          page_title: "purchase-completed",
          page_location: window.location.href,
        });
      });
      r?.request.catch((e: Error) => { 
        if (e.message.toLowerCase().indexOf('aborted') < 0) { console.log('Cant load'); } 
      });
    } catch (e) {
      console.error(e);
    }
    return () => {
      ignore = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !info ? <>Getting payment info</> : <>
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#ebebeb'}}>
      <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: '1px solid #c4c4c4', padding: 16, margin: '16px 0'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <div className="ribbon-inner"><Logo /></div>
          <div style={{display: 'flex'}}>
            <div style={{textAlign: 'right', fontSize: 10}}>
              <div style={{fontWeight: 800}}>Invoice: {info.id}</div>
              <div>{moment(info.created).format('DD-MMMM-YYYY')}</div>
              <div>PAID</div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{display: 'flex', fontSize: 10}}>

          <div>
            <p style={{fontSize: 14}}>From : Tarch Inc</p>
            <p>Carrera 25 #12 sur 59</p>
            <p>of 102, Medellin</p>
            <p>Antioquia, 050021</p>
            <p>Phone: +573012329000</p>
            <p>Email: ventas@tarch.co</p>
          </div>

          <div style={{margin: '0 8px'}}>
            <p style={{fontSize: 14}}>To : {info.name}</p>
            <p>{info.shipping.line1}</p>
            <p>{info.shipping.line2}, {info.shipping.city}</p>
            <p>{info.shipping.state}, {info.shipping.postal_code}</p>
            <p>Email: {info.email}</p>

          </div>

          <div>
            <p style={{fontSize: 14}}>Payment details</p>
            <p>Date: {moment(info.created).format('DD-MMMM-YYYY')}</p>
            {/* <p>VAT: DK888-777 </p> */}
            <p>Total Amount: {numeral(info.totals.total / 100).format('$0,0.00')}</p>
            {/* <p>Account Name: Flatter</p> */}
          </div>

        </div>

        <div style={{borderTop: '1px solid #c4c4c4', borderBottom: '1px solid #c4c4c4', padding: '22px 0'}}>
          <table cellSpacing={0} cellPadding={8}>
              <thead>
                <tr>
                  <th style={{width: "5%", textAlign: 'center'}}>#</th>
                  <th style={{width: "50%", textAlign: 'left'}}>Item</th>
                  <th style={{width: "15%", textAlign: 'right'}}>Size</th>
                  <th style={{width: "15%", textAlign: 'center'}}>Quantity</th>
                  {/* <th style={{width: "15%", textAlign: 'right'}}>Unit Price</th> */}
                  <th style={{width: "15%", textAlign: 'right'}}>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {info.products.map( (it, i) => 
                  <tr key={`${it.reference}-${it.size}-${it.priceInCents}`}>
                    <td style={{ textAlign: 'center', backgroundColor: '#ededed' }}>{i+1}</td>
                    <td style={{backgroundColor: '#ededed', textAlign: 'left' }}>{it.reference}</td>
                    <td style={{ textAlign: 'right', backgroundColor: '#ededed' }}>{it.size}</td>
                    <td style={{ textAlign: 'center', backgroundColor: '#ededed' }}>{it.quantity}</td>
                    {/* <td style={{ textAlign: 'right', backgroundColor: '#ededed' }}>$18</td> */}
                    <td style={{ textAlign: 'right', backgroundColor: '#ededed' }}>{numeral(it.priceInCents / 100).format('$0,0.00')}</td>
                  </tr>
                )}
              </tbody>
            </table>

        </div>

        <div>
          <div>
            <h3 style={{marginTop: 32, fontWeight: 400}}>THANK YOU!</h3>

            {/* <button ><i className="fa-solid fa-print"></i> Print Invoice</button> */}
          </div>
          <div>
            <p>Subtotal : {numeral((info.totals.total - info.totals.tax) / 100).format('$0,0.00')}</p>
            {/* <p>Discount (10%) : $101 </p> */}
            <p>Tax: {numeral((info.totals.tax) / 100).format('$0,0.00')}</p>
            {/* <p>VAT (8%) : $73 </p> */}
            <p>Total : {numeral(info.totals.total / 100).format('$0,0.00')}</p>
          </div>
        </div>
        <div>
        <Button onClick={() => navigate('/', {relative: 'path'})} style={{width: '100%'}}>
          Explore more products
        </Button>
        </div>
      </div>
    </div>
  </>
}