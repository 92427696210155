import React from 'react';
import './logo.css';

export function Logo() {
  return (
    <div className='tarch-logo-container'>
      <i className='tarch-logo'></i>
    </div>
  );
}
