import React from 'react';
import { IClientProductPage } from '../product-locations';


export const copper = (): IClientProductPage => {
  const clientFolder = `images/nebraska/copper`;
  return {
    reference: `nebraska-copper`,
    clientFolder,
    name: 'Nebraska Copper',
    header01: `${clientFolder}/header-01.jpg`,
    headerTitle: <>
      <h1 style={{textTransform: 'uppercase'}}>Nebraska</h1>
      <h3 style={{textTransform: 'uppercase', color: 'black'}}>Copper</h3>
    </>, 
    gallery02: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
    ],
    gallery01: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
    ],
    description: <>
      <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
      <p>
      These Texan ankle boots have the perfect height in the shaft and heel to be comfortable and trendy, and in this metallic copper color, you can create incredible outfits.
      </p>
      <div>
        <h4 style={{margin: '8px 0'}}>MATERIALS</h4>
        <ul>
          <li>Worn metallic copper cow leather</li>
          <li>Inner lining in badana leather</li>
          <li>Luxury insole in badana leather</li>
          <li>Neolite sole</li>
          <li>6 cm wooden heel</li>
        </ul>

        <h4 style={{margin: '8px 0'}}>SIZE</h4>
        <p>Our sizing is standard, but if you are between two sizes, we recommend choosing the larger size (e.g., if you are a 37.5, we recommend buying size 38).</p>
        
        <p>Made by hand, with love, by our artisans in Colombia</p>
      </div>
    </>,
  }
}
export const fire = (): IClientProductPage => {
  const clientFolder = `images/nebraska/fire`;
  return {
    reference: `nebraska-fire`,
    clientFolder,
    name: 'Nebraska Fire',
    header01: `${clientFolder}/header-01.jpg`,
    headerTitle: <>
      <h1 style={{textTransform: 'uppercase'}}>Nebraska</h1>
      <h3 style={{textTransform: 'uppercase', color: 'black'}}>Fire</h3>
    </>, 
    gallery02: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
    ],
    gallery01: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
      {src: `${clientFolder}/img-4.jpg`, type: 'image'},
    ],
    description: <>
      <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
      <p>
      These Texan ankle boots have the perfect height in the shaft and heel to be comfortable and trendy, White leather details, Wooden heel, Heel height 6.5 cm.
      </p>
      <div>
        <h4 style={{margin: '8px 0'}}>MATERIALS</h4>
        <ul>
          <li>Cow leather</li>
          <li>Lining in badana leather</li>
          <li>6.5 cm wooden heel</li>
        </ul>

        <h4 style={{margin: '8px 0'}}>SIZE</h4>
        <p>Our sizing is standard, but if you are between two sizes, we recommend choosing the larger size (e.g., if you are a 37.5, we recommend buying size 38).</p>
        
        <p>Made by hand, with love, by our artisans in Colombia</p>
      </div>
    </>,
  }
}