/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Gallery01, IImage, InvisibleButton } from '../../../components/gallery-01/gallery-01';
import { IProduct } from '../../../interfaces';
import numeral from 'numeral';
import * as helpers from '../../../helpers';
import './products-list.css';
import { useApp } from '../../../context-providers/app-provider/app-provider';

export const ProductsList = (props: { products: IProduct[] }) => {
  const [items, setItems] = React.useState<IImage[]>([]);
  let appCtx = useApp();
  React.useEffect(() => {
    const ps: IProduct[] = [...props.products]
      .sort((a,b) => a.category.localeCompare(b.category)); 
      // helpers.shuffleInPlace([...props.products]);
    setItems(ps.map(it => {
      const pi = helpers.productClientInfo.get(it.reference);
      return {
        src: `${pi?.clientFolder}/closeup-0.jpg`,
        type: 'image',
        scaleOnHover: true,
        children: <>
          <InvisibleButton link={`/p/${it.reference}`} />
        </>,
        suffixChildren: <>
          <div className='suffix'>
            { pi?.headerTitle }
          </div>
          <div className='suffix' style={{ marginBottom: 22 }}><h4 style={{}}>{numeral(it.priceUsd || 0).format('$0,0.00')}</h4></div>
        </>,
      }
    }));
  }, [props.products]);
  return <div className='products-list'>
    <h3 style={{margin: '16px 0'}}>{appCtx.category==='All' ? 'Explore products:' : appCtx.category}</h3>
    <div className='gallery'>
      <Gallery01 images={items} imageStyle={{ backgroundPosition: 'bottom center', backgroundSize: 'auto 100%' }}  />
    </div>
  </div>
}