import React from 'react';
import { IClientProductPage } from '../product-locations';


export const boldBlack = (): IClientProductPage => {
  const clientFolder = `images/andros-bold-black`;
  return {
    reference: `andros-bold-black`,
    clientFolder,
    name: 'Andros Bold Black',
    header01: `${clientFolder}/header-01.jpg`,
    headerTitle: <>
      <h1 style={{textTransform: 'uppercase'}}>Andros Bold</h1>
      <h3 style={{textTransform: 'uppercase', color: 'black'}}>Black</h3>
    </>, 
    gallery02: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
    ],
    gallery01: [
      {src: `${clientFolder}/img-0.jpg`, type: 'image'},
      {src: `${clientFolder}/img-1.jpg`, type: 'image'},
      {src: `${clientFolder}/img-2.jpg`, type: 'image'},
      {src: `${clientFolder}/img-3.jpg`, type: 'image'},
    ],
    description: <>
      <h4 style={{margin: '8px 0'}}>DESCRIPTION</h4>
      <p>
        You can wear these clogs on any occasion. &nbsp;
        They have a 2cm heel that you will love for its comfort. &nbsp; 
      </p>
      <div>
        <h4 style={{margin: '8px 0'}}></h4>
        <ul>
          <li>Upper in cow leather</li>
          <li>Inner lining in sheepskin leather</li>
          <li>Maxi buckle gold-plated</li>
        </ul>

        <h4 style={{margin: '8px 0'}}>SIZE</h4>
        <p>Order your usual size.</p>
        <p>Made by hand, with love, by our artisans in Colombia</p>
      </div>
    </>,
  }
}