/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import { useApp } from '../../context-providers/app-provider/app-provider';
import './home.css';
// import * as helpers from '../../helpers';
import { Header } from '../../components/header/header';
import { CartSide } from '../../components/cart-side/cart-side';
import { ProductsList } from './products-list/products-list';
import { Gallery02 } from '../../components/gallery-02/gallery-02';
import { IImage, InvisibleButton } from '../../components/gallery-01/gallery-01';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const [gallery, setGallery] = React.useState<IImage[]>([]);
  let appCtx = useApp();
  let navigate = useNavigate();

  React.useEffect(() => {
    const g: IImage[] = [];

    g.push({ src: 'images/home/img-0.jpg', type: 'image',
      children: <>
      <InvisibleButton onClick={(e) => { appCtx.changeCategory('Nolita'); navigate('/') }}><>
        <h2>Nolita Collection</h2>
        </>
      </InvisibleButton>
    </>
    });
    g.push({ src: 'images/home/img-1.jpg', type: 'image',
      children: <>
      <InvisibleButton onClick={(e) => { appCtx.changeCategory('Bela'); navigate('/') }}><>
        <h2>Bela Collection</h2>
        </>
      </InvisibleButton>
    </>
    });
    g.push({ src: 'images/home/img-2.jpg', type: 'image',
      children: <>
      <InvisibleButton onClick={(e) => { appCtx.changeCategory('Nebraska'); navigate('/') }}><>
        <h2>Nebraska Collection</h2>
        </>
      </InvisibleButton>
    </>
    });
    setGallery(g);
  }, []);

  return <>
    <Header />

    { appCtx.category === 'All' 
      ? <Gallery02 images={gallery}/> 
      : <div style={{marginTop: 82}}></div>
    }

    <FreeShipping />
    
    <ProductsList 
      products={appCtx.products
        .filter(it => appCtx.category === 'All' || it.category ===  appCtx.category )} 
    />
    </>
};

export const FreeShipping = () => {
  return <div className={`free-shipping`}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      Welcome to our exclusive Tarch USA 
      <span style={{fontSize: 24, margin: '0px 8px'}}>🇺🇸</span> 
      store.</div>
    {/* <div>Free shipping to the US</div> */}
  </div>
}