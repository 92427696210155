/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './toast.css';
import gsap, {Power3} from 'gsap';
import {bus} from './../../helpers';

export interface IToastParams { message: string; delayInSeconds?: number };
interface IProps {}
export const Toast = (props: IProps) => {
  const [ message, setMessage ] = React.useState('');
  const [ closingTW, setClosingTW ] = React.useState< gsap.core.Tween | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const showToast = (params: IToastParams) => {
    console.log('Showing Toast', params.message);
    closingTW?.kill();
    containerRef.current!.style.top = '-500px';
    containerRef.current!.style.opacity = '0';
    setClosingTW(null);
    setMessage(params.message);
    setClosingTW(gsap.to(containerRef.current, {
      duration: 0.5,
      top: 0,
      opacity: 1,
      ease: Power3.easeOut,
      onComplete: () => { hideToast(params.delayInSeconds || 10); }
    }));
  };
  const hideToast = (delay = 0) => {
    closingTW?.kill();
    setClosingTW(gsap.to(containerRef.current, { delay, duration: 0.5, top: -500, ease: Power3.easeIn, onComplete: () => {
      setClosingTW(null);
    } }));
  }

  React.useEffect(() => {
    const showToastFcn = (params: IToastParams) => showToast(params);
    bus.on('SHOW_TOAST', showToastFcn);
    return () => {
      bus.off('SHOW_TOAST', showToastFcn);
    }
  }, []);
  return <div ref={containerRef} className={'toast'} onClick={() => hideToast(0)}>
    <h4 className={'message'}>{message}</h4>
    {/* <div className={css.close} onClick={() => hideToast(0)}> */}
      {/* <span className={'mxm-toast-close'}></span> */}
    {/* </div> */}
  </div>;
};
