/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import { Logo } from '../../components/logo/logo';
import './checkout.css';
import { PurchaseAction } from './purchase-action';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { CartItem } from '../../components/cart-side/cart-side';
import { api } from '../../helpers';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';

export const Checkout = () => {
  let appCtx = useApp();
  let navigate = useNavigate();

  React.useEffect(() => {
    let ignore = false;
    try {
      gtag("event", "page_view", {
        page_title: "checkout",
        page_location: window.location.href,
      });
      const cart = [...appCtx.cart];
      const r = api.call(`${process.env.REACT_APP_API_URL}/stripe-init`, { cart });
      r?.request.then((req: any) => {
        if (ignore) { return; }
        appCtx.changestripePaymentId(req.paymentId);
        appCtx.changestripeClientSecret(req.clientSecret);
        appCtx.changeTotalPrice(req.totalPrice);
      });
      r?.request.catch((e: Error) => { 
        if (e.message.toLowerCase().indexOf('aborted') < 0) { navigate('/', {relative: 'path'}) } 
      });
      
    } catch (e) {
      console.error(e);
      console.error('je suis lao');
      navigate('/');
    }
    return () => {
      ignore = true;
    }
  }, []);

  return <div className='checkout'>
    <div style={{display: 'flex', margin: '24px 0 '}}>
      <div style={{fontSize: 32, display: 'flex', justifyContent: 'center', padding: '0 16px'}}><i className={'fa-solid fa-bag-shopping'}></i></div>
      <Logo />
    </div>
    <Summary />
    <PurchaseAction />
  </div>
}

const Summary = () => {
  const [showDetails, setShowDetails] = React.useState(false);
  let appCtx = useApp();
  return <div className='summary'>
    <div className='content'>
      <div className='top-info' onClick={() => setShowDetails(!showDetails)}>
        <div style={{display: 'flex', paddingLeft: 8}}>
          <div>{showDetails ? 'Hide ' : 'Show '}order summary</div>
          <div style={{marginLeft: 8}}>
            <i className={`fa-solid fa-caret-${showDetails ? 'up' : 'down'}`}></i>
          </div>
        </div>
        <div style={{fontWeight: 800, alignSelf: 'flex-end', paddingRight: 8}}>
          Total: {numeral((appCtx.totalPrice) / 100).format('$0,0.00')}
        </div>
      </div>
      <div className={`details ${showDetails ? 'details-shown' : ''}`}>
        <table cellPadding={0} cellSpacing={0} style={{padding: '32px 0'}}>
          <tbody>
          {appCtx.cart.map((it, i) => <CartItem item={it} key={`cart-side-${i}`} disabled={true} />)}
          </tbody>
        </table>

        <table className={`summary-table`}>
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td className='td-content'>{numeral((appCtx.totalPrice - appCtx.tax) / 100).format('$0,0.00')}</td>
            </tr>
            <tr>
              <td>Shipping</td>
              <td className='td-content'>{numeral(0).format('$0,0.00')}</td>
            </tr>
            <tr>
              <td>Tax</td>
              <td className='td-content'>{numeral((appCtx.tax) / 100).format('$0,0.00')}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td className='td-content' style={{fontWeight: 800, fontSize: '1.1em'}}>{numeral((appCtx.totalPrice) / 100).format('$0,0.00')}</td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>
  </div>
}