/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import './cart-side.css';
import { bus, productClientInfo } from '../../helpers';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { ICart } from '../../interfaces';
import { ButtonSecondary } from '../button/button-secondary';
import { Button } from '../button/button';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import gsap, {Power3} from 'gsap';

export const CartSide = () => {
  const [render, setRender] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [ tween, setTween ] = React.useState< gsap.core.Tween | null>(null);
  let appCtx = useApp();
  const navigate = useNavigate();
  const windowRef = React.useRef<HTMLDivElement>(null);

  const onClose = () => {setRender(false); }
  
  const goToCheckout = () => {
    navigate('/checkout');
  }
  React.useEffect(() => {
    let t = 0;
    appCtx.cart.forEach(it => {
      t += it.priceInCents * it.quantity;
    });
    setTotal(t / 100);
  }, [appCtx.cart]);
  React.useEffect(() => {
    tween?.kill();
    if (render) {
      if (!windowRef?.current) { return; }
      windowRef.current.style.right = '-250px';
      windowRef.current.style.opacity = '0';
      setTween(gsap.to(windowRef.current, {
        duration: 0.35,
        right: 0,
        opacity: 1,
        ease: Power3.easeOut,
      }));
    }
  }, [render]);
  React.useEffect(() => {
    const onShow = () => {
      setRender(true);
    }
    bus.on('show-side-cart', onShow);
    return () => {
      bus.off('show-side-cart', onShow);
    }
  }, []);
  return !render ? <></> : <div className='cart-side'>
    <div className='cover' onClick={() => onClose()}></div>
    <div ref={windowRef} className={`window window-visible`}>
      <div className='content'>
        <div style={{fontSize: 32, display: 'flex', justifyContent: 'center'}}><i className={'fa-solid fa-bag-shopping'}></i></div>
        <table cellPadding={0} cellSpacing={0} style={{marginTop: 32}}>
          <tbody>
            {appCtx.cart.map((it, i) => <CartItem item={it} key={`cart-side-${i}`} />)}
            {appCtx.cart.length === 0 && <tr><td><h4 className={'empty-cart'}>Empty Cart</h4></td></tr>}
          </tbody>
        </table>
      </div>
      <div style={{padding: '16px'}}>
        {appCtx.cart.length > 0 && <Button style={{width: '100%', fontSize: 12, letterSpacing: '0.15em'}} onClick={() => goToCheckout()}>Checkout &bull; {numeral(Number(total)).format('$0,0.00')}</Button> }
        <ButtonSecondary style={{width: '100%', fontSize: 12, letterSpacing: '0.15em'}} onClick={() => onClose()}>Close</ButtonSecondary>
      </div>
    </div>
  </div>
}
export const CartItem = (props: {item: ICart, disabled?: boolean}) => {
  const [reference, setReference] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [quantity, setQuantity] = React.useState(props.item.quantity);
  let appCtx = useApp();
  const removeItemFromCart = (e: any) => {
    e.preventDefault();
    const a = [...appCtx.cart];
    const index = a.findIndex(it => it.reference === props.item.reference && it.size === props.item.size);
    const ct = [...appCtx.cart].filter((it, i) => i !== index);
    appCtx.changeCart(ct);
  }
  React.useEffect(() => {
    const max = appCtx.products.find(it => it.reference === props.item.reference)?.sizes[props.item.size] || 0;

    if (quantity <= 0) { setQuantity(1); return; }
    if (quantity > max) { 
      bus.emit('SHOW_TOAST', { message: `We have ${max} in stock, contact us at ventas@tarch.co and we'll produce them for you`})
      setQuantity(max); 
      return; 
    }
    const a = [...appCtx.cart];
    const index = a.findIndex(it => it.reference === props.item.reference && it.size === props.item.size);
    a[index].quantity = quantity;
    appCtx.changeCart(a);

  }, [quantity]);
  React.useEffect(() => {
    const p = appCtx.products.find(it => it.reference === props.item?.reference)!;
    if (!p) { return;}
    setReference(`${p.reference}`);
    setPrice(`${p.priceUsd}`);
  }, [props.item]);
  return reference === '' ? <></> : <tr>
    <td><img src={`${productClientInfo.get(reference)?.clientFolder}/img-0.jpg`} alt='' height={80} width={'auto'} /></td>
    <td>
      <h5>{productClientInfo.get(reference)?.name}</h5>
      <div style={{ color: 'gray'}}>Size: {props.item.size}</div>
      <div className={'cart-item-size'}><input type='number' disabled={!!props.disabled} value={quantity} onChange={e => setQuantity(Number(e.currentTarget.value))} /></div>
      <h5>{numeral(Number(price)).format('$0,0.00')}</h5>
    </td>
    {!props.disabled && <td><a href={`/`} onClick={(e) => removeItemFromCart(e) }>Remove</a></td> }
  </tr>
}