/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { getStripePromise } from '../../app';
import { ICart } from '../../interfaces';
import CheckoutForm from './checkout-form';
import {Elements} from '@stripe/react-stripe-js';
import { api, bus } from '../../helpers';
import { Button } from '../../components/button/button';
import { useNavigate } from 'react-router-dom';

let updateReq: any;
export const PurchaseAction = () => {
  let appCtx = useApp();
  let navigate = useNavigate();
  const onBuyNow = () => {}
  const updateStripe = (o: { cart: ICart[], email: string }) => {
    const {cart, email} = o;
    if (!appCtx.stripePaymentId) { console.warn('No stripe payment Id setup'); return; }
    const id = appCtx.stripePaymentId;
    try { updateReq?.kill?.()} catch (e) {} finally { updateReq = undefined; }
    console.log(`updating with cart`, cart)
    const name = appCtx.addressName;
    const address = appCtx.address;
    updateReq = api.call(`${process.env.REACT_APP_API_URL}/stripe-intent-update`, { id, name, address, email, cart });
    updateReq.request.then((req: any) => {
      appCtx.changeTotalPrice(req.taxCalculation.amount_total);
      appCtx.changeTax(req.taxCalculation.tax_amount_exclusive);
      updateReq = undefined;
    });
  }
  React.useEffect(() => {
    bus.on('update-stripe', updateStripe);
    return () => {
      bus.off('update-stripe', updateStripe);
      try { updateReq?.kill?.()} catch (e) {} finally {updateReq = undefined;}
    }
  }, []);
  return <div style={{padding: '28px 0', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
  { appCtx.stripeClientSecret === '' || appCtx.cart.length === 0  
    ? <Button onClick={() => onBuyNow()}>
      <span style={{marginRight: 8}} onClick={() => navigate('/', {relative: 'path'})}>Return to purchase</span>
      <i className={"fa-solid fa-bag-shopping"}></i>
      </Button> 
    : <>
        <div style={{maxWidth: 680, width: '100%' }}>
          <Elements stripe={getStripePromise()} options={{ clientSecret: appCtx.stripeClientSecret }}>
            <CheckoutForm onUpdateIntent={(cart: ICart[], email: string) => updateStripe({cart, email})} />
          </Elements>
        </div>
        </>
    }
    </div>

}