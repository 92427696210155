import React from 'react';
import { Button } from '../../components/button/button';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header/header';

export const AboutUs = () => {
  const navigate = useNavigate();
  return <>
    <Header />
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#ebebeb', paddingTop: 64}}>
      <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: '1px solid #c4c4c4', padding: 32, margin: '16px 0', maxWidth: 600}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h1>WE ARE #TARCHLOVERS</h1>
        </div>

        <p style={{marginTop: 64}}>
          Tarch is a brand of high-quality handmade leather products that enhance the sophistication and empowerment of contemporary women. Our products always provide an elegant touch with modern designs and a youthful, feminine flair, complemented by handmade details, excellent quality, and comfort.
        </p>
        
        <img src={`images/about-us/store-01.jpg`} alt='store' width={'100%'} height={'auto'} />
        <p>
          We carefully select our materials, purchasing leather only from factories that use environmentally friendly processes. Our collections are limited to ensure exclusivity.
        </p>

        <Button onClick={() => navigate('/', {relative: 'path'})}>Return Home</Button>
      </div>
    </div>
  </>
}