import React from 'react';
import './gallery-01.css';
import { Link } from 'react-router-dom';
export interface IImage {
  src: string;
  children?: JSX.Element;
  suffixChildren?: JSX.Element;
  type: 'image' | 'video';
  scaleOnHover?: boolean;
}
export const Gallery01 = (props: { images: IImage[]; imageStyle?: React.CSSProperties; imageClassName?: string }) => {
  const [rand] = React.useState(`${Math.random()}`)
  return <div className={`gallery-01`}>
    <div className='images'>
      { props.images.map((img, i) => <div key={`gallery-01-${rand}-${i}`}>
        <div className={`image ${props.imageClassName ?? ''} ${img.scaleOnHover ? 'scale-on-hover' : ''}`}
          style={ {
            ...(img.type === 'image' ? { backgroundImage: `url(${img.src})` } : {}),
            ...(props.imageStyle || {})
          }}>
            { img.children }
            {img.type === 'video' && 
              <video autoPlay={true} muted={true} controls={true} width={'100%'} height={'auto'} loop={true}>
                <source src={`${img.src}`} type="video/mp4" />
              </video>}
          </div>
          {img.suffixChildren}
      </div>) }
    </div>
  </div>
}
export const InvisibleButton = (props: { 
  link?: string, 
  children?: JSX.Element; 
  onClick?: (e: any) => unknown;
  style?: React.CSSProperties;
  childrenStyle?: React.CSSProperties;
} ) => {
  const [style] = React.useState<React.CSSProperties>(props.style || { width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, zIndex: 1 });
  const [childrenStyle] = React.useState<React.CSSProperties>(props.childrenStyle || { position: 'absolute', right: 10,  bottom: 30,  zIndex: 3,  display: 'flex', alignItems: 'flex-end', flexDirection: 'column', justifyContent: 'flex-end', fontSize: 8, backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: 8, borderRadius: 4, });
  return <div className='invisible-button' style={{ ...style }}>
    { props.children && <div style={ props.childrenStyle || childrenStyle}>{ props.children }</div> }
    {props.link && <Link style={{ ...style, zIndex: 3, cursor: 'pointer', textDecoration: 'none' }} to={`${props.link}`} replace={true} reloadDocument={true}>&nbsp;</Link>}
    {props.onClick && <a href={'/'} onClick={(e) => {e.preventDefault(); props.onClick?.(e); }} style={style}>&nbsp;</a>}
  </div> 
}
