import React from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { Button } from '../../components/button/button';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { AddressForm } from "./address-form";
import * as helpers from '../../helpers';
import numeral from 'numeral';
import { ICart } from "../../interfaces";

interface IProps {
  onUpdateIntent: (cart: ICart[], email: string) => unknown; 
}
const CheckoutForm = (props: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [status, setStatus] = React.useState<'READY' | 'PROCESSING'>('READY');
  const [message, setMessage] = React.useState(''); 
  const [isLoading, setIsLoading] = React.useState(false);
  const [step, setStep] = React.useState(0);

  let appCtx = useApp();

  React.useEffect(() => {
    if (!stripe) { return; }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) { console.log(`No client secret`); return; }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setStatus('PROCESSING');
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) { return; }

    setIsLoading(true);
    gtag('event', `purchase-click`);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_CLIENT_URL}/#/purchase-completed`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      gtag('event', `purchase-failed
      `);
      if (error.decline_code === 'fraudulent') {

      }
      setMessage(error.message || '');
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs"
  }
  const changeEmail = (email: string) => {
    if (!helpers.isValidEmail(email)) { return; }
    appCtx.changeEmail(email)
  }
  React.useEffect(() => {
    if (!appCtx.email) { return; }
    props.onUpdateIntent(appCtx.cart, appCtx.email);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appCtx.email]);

  return <> 
    {status === 'PROCESSING' && <>
      <h3>Your payment is processing.</h3>
    </>}

    {status === 'READY' && <>
      <h3 style={{margin: '12px 0'}}>Payment</h3> 

      {step === 0 && <> 
        <LinkAuthenticationElement onChange={(e) => changeEmail(e.value.email) } />
        <AddressForm onComplete={() => setStep(0)} />
      </>}
      <form id="payment-form" onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>  
        {step === 0 && <>
          <h3 style={{marginTop: 16}}>Payment Details</h3>
          <span id={'payment-initiate'}></span>

          {/* <ExpressCheckoutPage />  */}
          {/* <div style={{margin: 16, display: 'flex', justifyContent: 'center'}}>or</div> */}

          <PaymentElement id="payment-element" options={paymentElementOptions} />


          {/* Show any error or success messages */} 
          {message !== '' && <div id="payment-message" style={{color: 'red', textAlign: 'center'}}>{message}</div>}

          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: '12px 0'}}>
            <div style={{fontSize: 12}}>Subtotal: {numeral((appCtx.totalPrice - appCtx.tax) / 100).format('$0,0.00')}</div>
            <div style={{fontSize: 12}}>Shipping: {numeral(0).format('$0,0.00')}</div>
            { appCtx.address && <>
              <div style={{fontSize: 12}}>Tax: {numeral(appCtx.tax / 100).format('$0,0.00')}</div>
              <div style={{fontWeight: 800}}>Total: {numeral((appCtx.totalPrice) / 100).format('$0,0.00')}</div>
            </>} 
          </div> 
          <Button disabled={isLoading || !stripe || !elements || !appCtx.address || !appCtx.email}>Pay Now</Button> 
        </>}
      </form>
    </>}
  </>;
};
 
export default CheckoutForm;