/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import { api } from '../../helpers';
import { useApp } from '../../context-providers/app-provider/app-provider';
import './product.css';
import * as helpers from '../../helpers';
import { IProduct } from '../../interfaces';
import { Button } from '../../components/button/button';
import { Header } from '../../components/header/header';
import { Gallery01 } from '../../components/gallery-01/gallery-01';
import { Gallery02 } from '../../components/gallery-02/gallery-02';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';
import { IClientProductPage } from '../../product-info/product-locations';
import { ExploreOtherProducts } from '../../components/explore-other-products/explore-other-products';
import { Sizes } from '../../components/sizes/sizes';
import { filterNewCart } from '../../app';
import gsap, {Power3} from 'gsap';
interface ISize {size: number; quantity: number}

export const Product = () => {
  const [productReference, setProductReference] = React.useState<string | undefined>();
  const [productInfo, setProductInfo] = React.useState<IClientProductPage>();
  const [product, setProduct] = React.useState<IProduct>();
  const [render, setRender] = React.useState(false);
  const [allSoldOut, setAllSoldOut] = React.useState<any>(false);
  const [sizes, setSizes] = React.useState<ISize[]>([]);

  let appCtx = useApp();
  let { reference } = useParams();

  React.useEffect(() => {
    if (!productReference) { return; }
    setProductInfo(helpers.productClientInfo.get(productReference));
  }, [productReference])
  React.useEffect(() => {
    let ignore = false;
    try {
      appCtx.changeCategory('All');
      setRender(false);
      setProductReference(`${reference}`.toLowerCase());
      window.scrollTo({top: 0});
      const r = api.call(`${process.env.REACT_APP_API_URL}/get-product`, { reference: `${reference}`.toLowerCase() });
      r?.request.then((req: any) => {
        if (ignore) { return; }
        // console.log(req);
        const product = req.product as IProduct;
        const products = req.products as IProduct[];
        appCtx.changeProducts(products);

        const breakDownOfSizes = Object.keys(product.sizes).map((s) => (
          { reference: product.reference, size: Number(s), quantity: product.sizes[s] }
        ));

        const soldout = breakDownOfSizes.some((it: any) => it.quantity > 0) === false;
        setRender(true);
        setProduct(product);
        setSizes(breakDownOfSizes.sort((a, b) => a.size - b.size));
        setAllSoldOut(soldout);
        
        if (soldout) { 
          appCtx.changeCart([]); 
          appCtx.changeTotalPrice(0); 
          return; 
        }

        const newCart = filterNewCart([...appCtx.cart], products);
        appCtx.changeCart(newCart);
        appCtx.changeTotalPrice(newCart.reduce((a, b) => a + b.priceInCents, 0));
        
      });
      r?.request.catch((e: Error) => { 
        if (e.message.toLowerCase().indexOf('aborted') < 0) { console.log('Cant load'); } 
      });
    } catch (e) {
      console.error(e);
    }
    return () => {
      ignore = true;
    }
  }, [reference]);
  
  return (!render || !productInfo || !productReference || !product) ? <div className='empty-page'></div> : <>
    <Header />

    <div style={{marginTop: 64}}></div>
    {/* <FreeShipping /> */}
    <MoreInfo sizes={sizes} allSoldOut={allSoldOut} productInfo={productInfo} product={product} />
         
    <ExploreOtherProducts products={appCtx.products} />
    </>
};

const MoreInfo = (props: { sizes: ISize[]; product: IProduct; productInfo: IClientProductPage; allSoldOut: boolean }) => {
  const [size, setSize] = React.useState(0);
  const [isPortrait, setIsPortrait] = React.useState(window.innerWidth < window.innerHeight);
  const windowRef = React.useRef<HTMLDivElement>(null);

  let appCtx = useApp();

  const onSetSelectedSize = (s: number) => { setSize(s); }

  const addToCart = () => {
    const p = props.product;
    const ct = [...appCtx.cart];
    const listProduct = appCtx.products.find(it => it.reference === p?.reference);
    if (!p || !listProduct) { return; }
    const existingIndex = ct.findIndex(it => it.reference === p.reference && it.size === size);
    let q = 0;
    if (existingIndex > -1) {
      ct[existingIndex].quantity += 1;
      q = ct[existingIndex].quantity;
    } else {
      ct.push({ reference: p.reference, quantity: 1, size, priceInCents: p.priceUsd * 100, });
    }

    if ((listProduct.sizes[size] || 0) - q < 0 ) {
      throw new Error('Not enough to add');
    };
    helpers.bus.emit('SHOW_TOAST', {message: `${props.productInfo!.name} added to cart`})
    appCtx.changeCart(ct);
  }
  React.useEffect(() => {
    setSize(appCtx.cart?.[0]?.size || 37);

    const resize = ({ isPortrait }: { isPortrait: boolean }) => { setIsPortrait(isPortrait); }
    helpers.bus.on('RESIZE', resize);

    let tw: gsap.core.Tween | undefined = undefined;
    if (windowRef.current) {
      windowRef.current.style.left = '100px';
      windowRef.current.style.opacity = '0';
      tw = gsap.to(windowRef.current, {
        duration: 1,
        left: 0,
        opacity: 1,
        ease: Power3.easeOut,
      });
    }

    return () => {
      try {
        tw?.kill();
        helpers.bus.off('RESIZE', resize);
        // window.screen?.orientation?.removeEventListener('change', resize);
      } catch (e) {
        console.log(e);
      }
    }
  }, []);
  
  return <div className={`more-info`} ref={windowRef} >
    <div className='content'>
      {!isPortrait && <div className='left'>
        <div>
          <Gallery01 
            images={props.productInfo?.gallery01 || []} 
            imageStyle={{ backgroundPosition: 'bottom center' }}
          />
        </div> 
      </div>}

      {isPortrait && <div className='portrait'>
        <Gallery02 images={props.productInfo?.gallery02 || []}/> 
      </div>}

      <div className='right' id="payment-initiate">
        <div className='inner' id="payment-initiate">
          <div>{props.productInfo.headerTitle}</div>
          <div style={{margin: '22px 0'}}>
            {(Number(props.product?.priceUsdPrevious || 0) > 0 ) && <div style={{textDecoration: 'line-through', color: 'black'}}>{numeral(props.product?.priceUsdPrevious || 0).format('$0,0.00')}</div> }
            <h2 style={{}}>{numeral(props.product?.priceUsd || 0).format('$0,0.00')}</h2>
            <div style={{fontSize: 8}}>Free shipping in the US</div>
          </div>
        </div>
        {props.allSoldOut
          ? <div className='sold-out'>So sorry, we just sold out of our last pair! 😅 </div>
          : <div style={{padding: 8}}>
              <Sizes sizes={props.sizes} size={size} productReference={props.productInfo.reference} onSetSelectedSize={(s) => onSetSelectedSize(s)} />
              <Button onClick={() => addToCart()} style={{width: '100%'}}>Add to Cart</Button>
            </div>
          }
          <div style={{padding: 8}}>
            {props.productInfo.description}
          </div>
      </div>
    </div>
  </div>
}
