import * as React from "react";
import { IAddress, ICart, ICategory, IProduct } from "../../interfaces";

interface IAppContextType {
  stripeClientSecret: string;
  changestripeClientSecret: (val: string) => void;

  stripePaymentId: string;
  changestripePaymentId: (val: string) => void;

  products: IProduct[];
  changeProducts: (val: IProduct[]) => void;
  categories: ICategory[];
  category: string;
  changeCategory: (val: string) => void;
  changeCategories: (val: ICategory[]) => void;

  cart: ICart[];
  addItemToCart: (val: ICart) => void;
  changeCart: (val: ICart[]) => void;
  
  totalPrice: number;
  changeTotalPrice: (val: number) => void;
  
  tax: number;
  changeTax: (val: number) => void;

  email?: string;
  changeEmail: (val: string) => void;

  addressName: string;
  changeAddressName: (val: string) => void;

  address: IAddress | undefined,
  changeAddress: (val?: IAddress) => void;
}
let AppContext = React.createContext<IAppContextType>(null!);

export function AppProvider({ children }: { children: React.ReactNode }) {
  let [stripeClientSecret, setstripeClientSecret] = React.useState('');
  let [stripePaymentId, setstripePaymentId] = React.useState('');
  let [email, setEmail] = React.useState<string | undefined>();
  let [addressName, setaddressName] = React.useState('');
  let [totalPrice, setTotalPrice] = React.useState(0);
  let [tax, setTax] = React.useState(0);
  let [products, setProducts] = React.useState<IProduct[]>([]);
  let [category, setCategory] = React.useState<string>('All');
  let [categories, setCategories] = React.useState<ICategory[]>([]);
  let [cart, setCart] = React.useState<ICart[]>(localStorage?.getItem?.('cart') ? JSON.parse(localStorage.getItem('cart')!) : []);
  let [address, setAddress] = React.useState<IAddress | undefined>(undefined);

  const changeTotalPrice = (val: number) => { setTotalPrice(val); }
  const changeTax = (val: number) => { setTax(val); }
  const addItemToCart = (val: ICart) => { const c = [...cart]; c.push(val); setCart(c); }
  const changeProducts = (val: IProduct[]) => setProducts(val);
  const changeCategory = (val: string) => setCategory(val);
  const changeCategories = (val: ICategory[]) => setCategories(val);
  const changeCart = (val: ICart[]) => {
    setCart(val);
    localStorage.setItem('cart', JSON.stringify(val));
  }
  const changeAddress = (val?: IAddress) => { setAddress(val); }
  const changestripeClientSecret = (val: string) => { setstripeClientSecret(val); }
  const changestripePaymentId = (val: string) => { setstripePaymentId(val); }
  const changeAddressName = (val: string) => { setaddressName(val); }
  const changeEmail = (val: string) => { setEmail(val); }

  let value = {
    stripeClientSecret,
    changestripeClientSecret,

    stripePaymentId,
    changestripePaymentId,

    totalPrice,
    changeTotalPrice,

    tax,
    changeTax,
    
    products,
    changeProducts,

    categories,
    changeCategories,
    category,
    changeCategory,

    cart,
    addItemToCart,
    changeCart,

    email,
    changeEmail,

    address,
    changeAddress,
    addressName,
    changeAddressName,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
export function useApp() {
  return React.useContext(AppContext);
}
