import * as request from 'superagent';
import * as helpers from './../';

export interface IApiRequest { request: Promise<any>; kill: () => unknown; }
const apiRequests: { id: string; req: request.SuperAgentRequest }[] = [];

export const call = (url: string, payload?: any) => {
  const req = request.post(url).withCredentials();
  const id = `${helpers.randomString(8)}`;
  const r = { id, req };
  apiRequests.push(r);
  const cleanup = () => { 
    const index = apiRequests.findIndex(a => a.id === id);
    if ( index < 0 ) { return; }
    try { req.abort(); } catch (e) { /* console.error('slots.init.kill', e); */ }
    apiRequests.splice(index, 1);
   };
  const promise = new Promise<any>((resolve, reject) => {
    req
      .send(payload)
      .then(res => { cleanup(); resolve(res?.body) } )
      .catch(e => { cleanup(); reject(e); });
  });
  return {
    request: promise,
    kill: () => cleanup(),
  }
}
