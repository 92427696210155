/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import './burger-menu.css';
import { bus } from '../../../helpers';
import { useApp } from '../../../context-providers/app-provider/app-provider';
import { useNavigate } from 'react-router-dom';
import gsap, {Power3} from 'gsap';

export const BurgerMenu = () => {
  const [render, setRender] = React.useState(false);
  const [ tween, setTween ] = React.useState< gsap.core.Tween | null>(null);
  let appCtx = useApp();
  const navigate = useNavigate();
  const windowRef = React.useRef<HTMLDivElement>(null);

  const onClose = () => {setRender(false); }
  
  const nav = (e: any, category: string) => {
    e.preventDefault();
    appCtx.changeCategory(category);
    navigate('/', { relative: 'path' });
    setRender(false);
  }
  React.useEffect(() => {
    tween?.kill();
    if (render) {
      if (!windowRef?.current) { return; }
      windowRef.current.style.left = '-500px';
      windowRef.current.style.opacity = '0';
      setTween(gsap.to(windowRef.current, {
        duration: 0.35,
        left: 0,
        opacity: 1,
        ease: Power3.easeOut,
      }));
    }
  }, [render]);
  React.useEffect(() => {
    const onShow = () => {
      setRender(true);
    }
    bus.on('show-burger-menu', onShow);
    return () => {
      bus.off('show-burger-menu', onShow);
    }
  }, []);
  return !render ? <></> : <div className='burger-menu'>
    <div className='cover' onClick={() => onClose()}></div>
    <div ref={windowRef} className={`window window-visible`}>
      <div className='content'>
        <div className='close' onClick={() => onClose()}>
          <i className={"fa-solid fa-xmark"}></i>
        </div>
        <table cellPadding={0} cellSpacing={0} style={{marginTop: 32}}>
          <tbody>
            {appCtx.categories.map(it => <tr key={`bm-cat-${it.name}`}>
              <td>
                <h4><a href={'tarch.co'} onClick={(e) => nav(e, it.name)}>{it.name}</a></h4>
              </td>
            </tr>)}
            <tr>
              <td>
                <a href={'tarch.co'} onClick={(e) => { e.preventDefault(); navigate('about-us'); }}>About us</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='social-media'>
        <div className='item'>
          <a href={`https://www.instagram.com/tarch.co/`} target='_blank'>
            <span style={{fontSize: 22 }}><i className='fa-brands fa-instagram' /></span>
          </a>
        </div>
        <div className='item'>
          <a href={`https://www.tiktok.com/@tarch.co`} target='_blank'>
            <span style={{fontSize: 22 }}><i className='fa-brands fa-tiktok' /></span>
          </a>
        </div>
      </div>
      <div><h4>Currency: USD</h4></div>
    </div>
  </div>
}
