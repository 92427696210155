/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import { Logo } from '../logo/logo';
import './header.css';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { bus } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { BurgerMenu } from './burger-menu/burger-menu';
import { CartSide } from '../cart-side/cart-side';

export const Header = () => {
  const [bgClass, setBgClass] = React.useState('');
  const [isPortrait, setIsPortrait] = React.useState(window.innerWidth < window.innerHeight);

  let appCtx = useApp();
  let navigate = useNavigate();

  const openBurger = (e: any) => { e.preventDefault(); bus.emit('show-burger-menu');}
  const openCart = (e: any) => { e.preventDefault(); bus.emit('show-side-cart');}
  
  const nav = (e: any, category: string) => {
    e.preventDefault();
    appCtx.changeCategory(category);
    navigate('/', {relative: 'path'});
  }
  
  React.useEffect(() => {
    const scroll = (o: Event) => {
      setBgClass(window.scrollY > 100 ? 'header-white-bg' : '');
      console.log();
    }
    const resize = (o: { isPortrait: boolean; width: number; height: number }) => {
      setIsPortrait(o.isPortrait);
    }
    bus.on('RESIZE', resize);
    window.addEventListener('scroll', scroll);
    return () => {
      bus.off('RESIZE', resize);
      window.removeEventListener('scroll', scroll);
    }
  }, []);
  return <><div className={`header ${bgClass}`}>

    <div onClick={(e) => {appCtx.changeCategory("All"); navigate('/');}} style={{cursor: 'pointer'}}>
      <Logo />
    </div>
    { isPortrait ? <>
      <BurgerMenu />
      <a href='https://tarch.co' className='left' onClick={(e) => openBurger(e)}><i className="fa-solid fa-bars"></i></a>
    </> : <div className='horizontal-menu'>
      {appCtx.categories.map(it => <div key={`bm-cat-${it.name}`}>
        <a href={'tarch.co'} onClick={(e) => nav(e, it.name)}>{it.name}</a>
      </div>)}
      <a href={'tarch.co'} onClick={(e) => { e.preventDefault(); navigate('/about-us', { relative: 'path'}); }}>About us</a>
    </div> }
    
    <a href='https://tarch.co' className='right' onClick={(e) => openCart(e)}>CART ({ appCtx.cart.length })</a>
  </div>
  
  <CartSide />
  </>
}