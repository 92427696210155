import React from 'react';
import './button.css';

export const ButtonSecondary = (props: { disabled?: boolean, children: any, onClick?: (e?: any) => unknown, style?: React.CSSProperties }) => {
  return <button 
    disabled={props.disabled} id="submit"
    className='button-secondary'
    style={{...(props.style || {})}}
    onClick={props.onClick}
    >
  {props.children}
</button>
}