import React from 'react';
import { useApp } from '../../context-providers/app-provider/app-provider';
import { bus } from '../../helpers';
import "./sizes.css";

export const Sizes = (props: {size: number; sizes: { size: number; quantity: number}[]; productReference: string; onSetSelectedSize: (size: number) => unknown }) => {
  
  let appCtx = useApp();
  const changeSelectedSize = async (size: number) => {
    gtag('event', `change-size`, { 'size': size });
    const p = appCtx.products.find(it => it.reference === props.productReference);
    if (!p) { throw new Error('Cannot change size'); }
    props.onSetSelectedSize(size);
  }
  return <>
    <h4 style={{margin: '0px 0 8px 0'}}>Choose size</h4>
    <div className='sizes-sel'>
      {props.sizes.map(it => 
        <a key={`size-${it.size}`} 
          href={`${process.env.REACT_APP_CLIENT_URL}`} 
          onClick={(e) => { e.preventDefault(); it.quantity > 0 && changeSelectedSize(it.size); }}
          className={`
            product-size ${props.size === it.size ? 'product-size-selected' : ''}
            ${it.quantity === 0 ? 'product-size-disabled' : ''}
          `}>
          {it.size}
        </a>)}
    </div>
    <div className={`table-sizes-link`}>
      <a href={`${process.env.REACT_APP_CLIENT_URL}`} onClick={(e) => { e.preventDefault(); bus.emit('show-table-sizes')}}>Show sizes guide</a>
    </div>
</>
}