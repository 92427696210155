import React from 'react';
import { bus } from '../../helpers';
import './table-sizes.css';
import { Button } from '../button/button';

export const TableSizes = () => {
  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    const callback = () => {
      setRender(true);
      gtag("event", "page_view", {
        page_title: "table-sizes",
        page_location: window.location.href
      });
    }
    bus.on('show-table-sizes', callback);
    
    return () => {
      bus.off('show-table-sizes', callback);
    }
  }, []);
  return !render ? <></> : <div className='table-sizes'>
    <div className='table-sizes-window'>
      <h3 style={{margin: '12px 0 24px 0'}}>Size Guide</h3>
      <table cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr>
            <td>
              <div>Measurements</div>
              <div>(cms)</div>
            </td>
            <td>22</td>
            <td>22.7</td>
            <td>23.3</td>
            <td>24</td>
            <td>24.7</td>
            <td>25.3</td>
            <td>26</td>
          </tr>
          <tr>
            <td>USA</td>
            <td>-</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
          </tr>
          <tr>
            <td>EUR</td>
            <td>35</td>
            <td>36</td>
            <td>37</td>
            <td>38</td>
            <td>39</td>
            <td>40</td>
            <td>41</td>
          </tr>
          <tr>
            <td>COLOMBIA</td>
            <td>34</td>
            <td>35</td>
            <td>36</td>
            <td>37</td>
            <td>38</td>
            <td>49</td>
            <td>40</td>
          </tr>
          <tr>
            <td>MEXICO</td>
            <td>22</td>
            <td>23</td>
            <td>23.5</td>
            <td>24</td>
            <td>25</td>
            <td>25.5</td>
            <td>26</td>
          </tr>
          <tr>
            <td>BRASIL</td>
            <td>33</td>
            <td>34</td>
            <td>35</td>
            <td>36</td>
            <td>37</td>
            <td>38</td>
            <td>39</td>
          </tr>
        </tbody>
      </table>
      <div className='description'>
        Our fit tends towards the smaller side, if you are between 2 sizes, we recommend the upper size. (e.g. if you are 5.5, we recommend 6)
        Made by hand, with love, by our artisans in Colombia
      </div>
      <Button onClick={() => setRender(false)} style={{margin: '24px 0 0 0'}}>
        Close
      </Button>
    </div>
  </div>
}