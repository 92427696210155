/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-target-blank */
import React from 'react';
import './gallery-02.css';
import { IImage } from '../gallery-01/gallery-01';
import { bus } from '../../helpers';

export const Gallery02 = (props: { images: IImage[]; }) => {
  const [rand] = React.useState(`${Math.random()}`);
  const [c, setC] = React.useState(-1);
  React.useEffect(() => {
    const anim = () => { setC(x => (x + 1) >= props.images.length ? 0 : (x + 1)); }
    let interval: any = setInterval(anim, 5000);
    const forceTo = (r: string, i: number) => {
      if (r !== rand) { return; }
      setC(i);
      clearInterval(interval);
      interval = setInterval(anim, 5000);
    }
    setC(0);
    bus.on('change-gallery-02', forceTo);
    return () => {
      bus.off('change-gallery-02', forceTo);
      clearInterval(interval);
      interval = undefined;
    }
  }, [props.images]);
  return <div className={`gallery-02`}>
    <div className='images'>
      { props.images.map((img, i) => (
        <div key={`gallery-02-${rand}-${i}`} 
          className={`image ${c === i ? 'image-sel' : ''}`} 
          style={ { ...({backgroundImage: `url(${img.src})` }) }}>
            { img.children }
        </div>)
      )}

      <div className='dots'>
        { props.images.map((img, i) => (
          <div key={`gallery-02-${rand}-${i}-ic`} className={`dot`} onClick={(e) => { e.preventDefault(); bus.emit('change-gallery-02', rand, i); }}>
            <i className={`${c === i ? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle'}`}></i>
          </div>)
        )}
      </div>
    </div>
  </div>
}
