/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Gallery01, IImage, InvisibleButton } from '../gallery-01/gallery-01';
import { IProduct } from '../../interfaces';
import numeral from 'numeral';
import * as helpers from '../../helpers';
import './explore-other-products.css';

export const ExploreOtherProducts = (props: {products: IProduct[], image?: {width: number; height: number}}) => {
  const [items, setItems] = React.useState<IImage[]>([]);
  React.useEffect(() => {
    const ps: IProduct[] = helpers.shuffleInPlace([...props.products]).filter((it, i) => i < 3);
    setItems(ps.map(it => {
      const pi = helpers.productClientInfo.get(it.reference);
      return {
        src: `${pi?.clientFolder}/closeup-0.jpg`,
        type: 'image',
        scaleOnHover: true,
        children: <>
          <InvisibleButton link={`/p/${it.reference}`}/>
        </>,
        suffixChildren: <>
          <div className='suffix'>
            { pi?.headerTitle }
          </div>
          <div className='suffix' style={{ marginBottom: 22 }}><h4 style={{}}>{numeral(it.priceUsd || 0).format('$0,0.00')}</h4></div>
        </>,
      }
    }));
  }, [props.products]);
  return <div className='explore-other-products'>
    <h3 style={{margin: '16px 0'}}>Explore products:</h3>
    <div className='gallery'>
      <Gallery01 images={items} imageStyle={{ 
        ...(props.image || {width: 300, height: 300}), 
        backgroundPosition: 'bottom center' }}  />
    </div>
  </div>
}